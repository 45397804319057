.news-slide {
    padding: 10px;
}

.card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.card:hover {
    transform: translateY(-5px);
}

.card-title {
    font-weight: bold;
    color: #333;
}

.card-text {
    color: #555;
}

.card-source {
    color: #888;
}

/* Fixed image size for uniformity */
.fixed-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

/* Limit the title to one line and truncate with ellipsis */
.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

/* Limit the description to 3 lines and truncate with ellipsis */
.three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    color: #555;
}
