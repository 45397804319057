.hover-scale:hover {
    transform: scale(1.05);
}

.hover-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease-in-out;
}

.ratio:hover .hover-overlay {
    opacity: 1 !important;
}