.custom-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.card-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.card-image {
  max-width: 100%;
  height: auto;
}

.card-body1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-body1 .card-text {
  font-weight: bold;
  font-size: 1rem;
}

.blog-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.blog-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-item {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .blog-item {
    margin-bottom: 3rem;
  }
}
